import React from 'react'
import classNames from 'classnames'
import { Formik } from 'formik'
import scrollTo from 'gatsby-plugin-smoothscroll'
import _ from 'lodash'
import * as Yup from 'yup'
import { Icon, Button, Form, Grid, Progress, Message } from 'semantic-ui-react'
import TextInput from './TextInput'
import { subscribeNewsletter } from '../services/bullarum.service'
import Logo from '../images/logos/contego-logo.png'

import headerStyle from '../styles/navbar.module.scss'

const NewsletterSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email format')
})

const Footer = () => {
  const [hasError, setHasError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [isSuccess, setIsSuccess] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')

  const handleSubmission = React.useCallback((values, actions) => {
    // sanitize data before sending
    const sanitizedData = {
      email_address: _.trim(values.email),
      status: 'subscribed',
      language: 'en'
    }
    // submitting form value
    actions.setSubmitting(true)
    subscribeNewsletter(sanitizedData)
      .then(res => {
        setIsSuccess(true)
        setSuccessMessage(res.data.message)
      })
      .catch(error => {
        setHasError(true)
        if (error.response) {
          setErrorMessage(error.response.data)
        } else {
          setErrorMessage('Something wrong happened. Please try again later!!!')
        }
      })
      .finally(() => {
        actions.setSubmitting(false)
        actions.resetForm()
        setTimeout(() => {
          setHasError(false)
          setIsSuccess(false)
        }, 5000)
      })
  }, [])

  return (
    <>
      <footer className="mt-4 shadow">
        <div className="container-fluid px-0 py-4">
          <div className="row px-4">
            <div className="col-lg-4 py-4 pr-4">
              <h1>OUR SHOWROOMS</h1>
              <div className="d-flex flex-column">
                <h4 className="w-100 text-uppercase text-secondary">Garden Grove, CA</h4>
                <a
                  href="https://goo.gl/maps/z7iY7nsy7sLVv1f28"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  12856 Brookhurst St, Garden Grove, CA 92840 USA
                </a>
                <p>
                  <strong className="text-secondary">MON-SUN</strong> 9:00AM - 5:30PM
                </p>
              </div>
              <div className="d-flex flex-column mt-4">
                <h4 className="w-100 text-uppercase text-secondary">Houston, TX</h4>
                <a
                  href="https://goo.gl/maps/tS79wr6Dnm8vazNf8"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  6787A Wilcrest Dr, Houston, TX 77072 USA
                </a>
                <p className="d-block mb-0">
                  <strong className="text-secondary">MON - THUR</strong> 10:00AM - 5:00PM
                </p>
                <p className="d-block mb-0">
                  <strong className="text-secondary"> SAT</strong> 9:00AM - 3:00PM
                </p>
                <p className="d-block mb-0">
                  <strong className="text-secondary"> SUN</strong> 10:00AM - 5:00PM
                </p>
              </div>
            </div>
            <div className="col-lg-3 py-4">
              <h1>QUICK LINKS</h1>
              <ul className={classNames(headerStyle.navBar, 'd-flex flex-wrap p-0')}>
                <li className={classNames(headerStyle.navBarItem, 'mb-2')}>
                  <a
                    href="https://www.contegospa.com/faq"
                    className={classNames(headerStyle.navBarLink, 'py-2')}
                  >
                    F.A.Q
                  </a>
                </li>
                <li className={classNames(headerStyle.navBarItem, 'mb-2')}>
                  <a
                    href="https://www.contegospa.com/term-conditions"
                    className={classNames(headerStyle.navBarLink, 'py-2')}
                  >
                    Term & Conditions
                  </a>
                </li>
                <li className={classNames(headerStyle.navBarItem, 'mb-2')}>
                  <a
                    href="https://www.contegospa.com/user-manuals/magna"
                    className={classNames(headerStyle.navBarLink, 'py-2')}
                  >
                    User Manuals
                  </a>
                </li>
                <li className={classNames(headerStyle.navBarItem, 'mb-2')}>
                  <a
                    href="https://www.contegospa.com/contact-us"
                    className={classNames(headerStyle.navBarLink, 'py-2')}
                  >
                    Contact Us
                  </a>
                </li>
                <li className={classNames(headerStyle.navBarItem, 'mb-2')}>
                  <a
                    href="https://www.contegospa.com/pedicure-spa-chairs/magna"
                    className={classNames(headerStyle.navBarLink, 'py-2')}
                  >
                    Pedicure Spa Chairs
                  </a>
                </li>
                <li className={classNames(headerStyle.navBarItem, 'mb-2')}>
                  <a
                    href="https://www.contegospa.com/accessories"
                    className={classNames(headerStyle.navBarLink, 'py-2')}
                  >
                    Accessorries
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 py-4">
              <h1>NEWSLETTER</h1>
              <p className="pb-2">
                Subscribe to our newsletter to receive new updates on products, tutorials and
                promotions
              </p>
              <Formik
                initialValues={{ email: '' }}
                onSubmit={handleSubmission}
                validationSchema={NewsletterSchema}
                render={({ handleSubmit, isSubmitting, isValid }) => (
                  <div>
                    <Form onSubmit={handleSubmit} noValidate className="attached fluid">
                      <Grid stackable padded>
                        <Grid.Row>
                          <Grid.Column>
                            <TextInput
                              name="email"
                              label="Email"
                              placeholder="example@domain.com"
                              autoComplete="email"
                              required
                            />
                          </Grid.Column>
                        </Grid.Row>
                        {isSubmitting && (
                          <Progress percent={100} attached="top" active size="medium" />
                        )}
                        <Grid.Row>
                          <Grid.Column>
                            <div
                              className="g-recaptcha"
                              data-sitekey="6LeMKsIUAAAAAP5BqUuVVPJAke81nZojIuxiYhVM"
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <button
                              type="submit"
                              className="ui button"
                              disabled={isSubmitting || !isValid}
                            >
                              Submit
                            </button>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Form>
                    {hasError && (
                      <Message attached="bottom" negative>
                        <Message.Header>Error!!!</Message.Header>
                        {errorMessage}
                      </Message>
                    )}
                    {isSuccess && (
                      <Message attached="bottom" positive>
                        <Message.Header>Thanks for subscribing to our newsletter!!!</Message.Header>
                        {successMessage}
                      </Message>
                    )}
                  </div>
                )}
              />
            </div>
            <div className="scroll-top-container col-lg-1 p-2">
              <button
                type="button"
                className="action-button mb-2 rounded py-2 mx-2"
                onClick={() => {
                  scrollTo('#header')
                }}
                label="go to top"
              >
                <Icon name="chevron up" className="mr-0" />
              </button>
              <a
                href="https://www.facebook.com/contegospa/"
                rel="noopener noreferrer"
                target="_blank"
                className="mb-2 mr-2"
                aria-label="facebook link"
              >
                <Icon size="large" name="facebook official" />
              </a>
              <a
                href="https://www.instagram.com/contegospa/"
                rel="noopener noreferrer"
                target="_blank"
                className="mb-2 mr-2"
                aria-label="instagram link"
              >
                <Icon size="large" name="instagram" />
              </a>
              <a
                href="https://www.youtube.com/channel/UC7kqMnIleOcvV76jwmNb7Rg/featured"
                rel="noopener noreferrer"
                target="_blank"
                className="mb-2 mr-2"
                aria-label="youtube link"
              >
                <Icon size="large" name="youtube play" />
              </a>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between align-items-center flex-wrap px-4 py-2">
            <div className="py-2 mr-4">
              <h6>© 2019 Contego Spa Design Inc. All Rights Reserved</h6>
            </div>
            <img src={Logo} alt="contego logo" className="my-2 mr-4" width="140px" height="auto" />
            <ul className={classNames(headerStyle.navBar, 'd-flex flex-wrap py-2 px-0')}>
              <li className={classNames(headerStyle.navBarItem, 'mb-2')}>
                <a
                  className={headerStyle.navBarLink}
                  href="https://www.contegospa.com/term-conditions"
                >
                  Term & Conditions
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
