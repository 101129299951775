/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import classNames from 'classnames'
import Logo from '../images/logos/contego-logo.png'
import headerStyle from '../styles/navbar.module.scss'
import '../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss'

const Header = () => {
  // isActive is used to control menu isActive toggle
  const [isActive, setIsActive] = React.useState(false)

  // handle megamenu click on desktop view
  const handleMegaMenuClick = React.useCallback(e => {
    // Getting all elements which we want to manipulate
    e.preventDefault()
    const referenceId = e.target.id
    const megaMenuElement = document.getElementById(`for-${referenceId}`)
    const dropdownIconElement = document.getElementById(`dropdown-icon-${referenceId}`)

    // open mega menu
    megaMenuElement.style.visibility = 'visible'
    megaMenuElement.style.height = '100px'

    // change dropdown icon
    dropdownIconElement.classList.remove('down')
    dropdownIconElement.classList.add('up')

    // add an event listener to close mega menu
    // option once since we only need to perform this event once
    // option capture to prevent the hosted element has preventDefault()
    // or stopPropagate()
    document.addEventListener(
      'click',
      e => {
        // hide mega menu
        megaMenuElement.style.height = '0px'

        // change dropdown icon
        dropdownIconElement.classList.remove('up')
        dropdownIconElement.classList.add('down')
      },
      { once: true, capture: true }
    )
  }, [])

  /*
   * Mobile
   */

  // handle dropdown menu click in mobile view
  const handleDropDownMenuClick = React.useCallback(e => {
    // Getting all elements which we want to manipulate
    const referenceId = e.target.id
    const mobileDropdownMenuElement = document.getElementById(`for-${referenceId}`)
    const dropdownIconElement = document.getElementById(`dropdown-icon-${referenceId}`)

    // show mobile dropdown menu
    mobileDropdownMenuElement.style.visibility = 'visible'
    mobileDropdownMenuElement.style.height = 'auto'

    // change dropdown icon
    dropdownIconElement.classList.remove('down')
    dropdownIconElement.classList.add('up')

    // add an event listener to close mega menu
    // option once since we only need to perform this event once
    // option capture to prevent the hosted element has preventDefault()
    // or stopPropagate()
    document.addEventListener(
      'click',
      e => {
        // hide dropdown menu
        mobileDropdownMenuElement.style.height = '0px'

        // change dropdown icon
        dropdownIconElement.classList.remove('up')
        dropdownIconElement.classList.add('down')
      },
      { once: true, capture: true }
    )
  }, [])

  // toggle menu icon
  const toggleMenu = React.useCallback(() => {
    const navBarContainerElement = document.getElementById('mobile-nav-bar-container')
    setIsActive(isActive => !isActive)
    if (!isActive) {
      navBarContainerElement.style.left = '0'
    } else {
      navBarContainerElement.style.left = '-100%'
    }
  }, [isActive])

  return (
    <>
      <div className={classNames(headerStyle.header, 'shadow-sm py-2 px-2')} id="header">
        <div className="px-2">
          <AniLink paintDrip duration={1.62} hex="#ffffff" to="/">
            <img src={Logo} alt="contego logo" className="m-0" width="140px" height="auto" />
          </AniLink>
        </div>
        <ul className={classNames(headerStyle.navBar, 'd-none d-xl-flex align-items-center')}>
          <li className={headerStyle.navBarItem}>
            <AniLink
              paintDrip
              duration={1.62}
              hex="#ffffff"
              activeClassName={headerStyle.activeNavBarItem}
              className={headerStyle.navBarLink}
              to="/"
            >
              HOME
            </AniLink>
          </li>
          <li className={headerStyle.navBarItem}>
            <AniLink
              paintDrip
              duration={1.62}
              hex="#ffffff"
              activeClassName={headerStyle.activeNavBarItem}
              className={headerStyle.navBarLink}
              to="/pedicure-spa-chairs/magna"
            >
              PEDICURE CHAIRS
            </AniLink>
          </li>
          <li className={headerStyle.navBarItem}>
            <AniLink
              paintDrip
              duration={1.62}
              hex="#ffffff"
              activeClassName={headerStyle.activeNavBarItem}
              className={headerStyle.navBarLink}
              to="/accessories"
            >
              ACCESSORIES
            </AniLink>
          </li>
          <li className={headerStyle.navBarItem}>
            <AniLink
              paintDrip
              duration={1.62}
              hex="#ffffff"
              activeClassName={headerStyle.activeNavBarItem}
              className={headerStyle.navBarLink}
              to="/about"
            >
              ABOUT
            </AniLink>
          </li>
          <li className={headerStyle.navBarItem}>
            <AniLink
              paintDrip
              duration={1.62}
              hex="#ffffff"
              activeClassName={headerStyle.activeNavBarItem}
              className={headerStyle.navBarLink}
              to="/contact-us"
            >
              CONTACT
            </AniLink>
          </li>
          <li className={headerStyle.navBarItem}>
            <a
              className={headerStyle.navBarLink}
              onClick={handleMegaMenuClick}
              id="support-link"
              href="#"
            >
              SUPPORT
              <i
                aria-hidden="true"
                className="chevron down small icon"
                id="dropdown-icon-support-link"
                onClick={e => e.stopPropagation()}
              />
            </a>
            <div className={classNames(headerStyle.megaMenu, 'shadow')} id="for-support-link">
              <h1 className="mr-4 mb-0">Support</h1>
              <ul
                className={classNames(
                  headerStyle.navBar,
                  'd-none d-md-flex d-xl-flex align-items-center'
                )}
              >
                <li className={headerStyle.navBarItem}>
                  <AniLink
                    paintDrip
                    duration={1.62}
                    hex="#ffffff"
                    activeClassName={headerStyle.activeNavBarItem}
                    className={headerStyle.navBarLink}
                    to="/faq/general"
                  >
                    FAQ
                  </AniLink>
                </li>
                <li className={headerStyle.navBarItem}>
                  <AniLink
                    paintDrip
                    duration={1.62}
                    hex="#ffffff"
                    activeClassName={headerStyle.activeNavBarItem}
                    className={headerStyle.navBarLink}
                    to="/user-manuals/novo"
                  >
                    User Manuals
                  </AniLink>
                </li>
                <li className={headerStyle.navBarItem}>
                  <AniLink
                    paintDrip
                    duration={1.62}
                    hex="#ffffff"
                    activeClassName={headerStyle.activeNavBarItem}
                    className={headerStyle.navBarLink}
                    to="/ip"
                  >
                    IP
                  </AniLink>
                </li>
                <li className={headerStyle.navBarItem}>
                  <AniLink
                    paintDrip
                    duration={1.62}
                    hex="#ffffff"
                    activeClassName={headerStyle.activeNavBarItem}
                    className={headerStyle.navBarLink}
                    to="/term-conditions"
                  >
                    Term & Conditions
                  </AniLink>
                </li>
              </ul>
            </div>
          </li>
          <li className={headerStyle.navBarItem}>
            <a className={headerStyle.navBarLink} id="media-link" onClick={handleMegaMenuClick}>
              MEDIA
              <i
                aria-hidden="true"
                className="chevron down small icon"
                id="dropdown-icon-media-link"
                onClick={e => e.stopPropagation()}
              />
            </a>
            <div className={classNames(headerStyle.megaMenu, 'shadow')} id="for-media-link">
              <h1 className="mr-4 mb-0">Media</h1>
              <ul className={classNames(headerStyle.navBar, 'd-none d-md-flex d-xl-flex')}>
                <li className={headerStyle.navBarItem}>
                  <AniLink
                    paintDrip
                    duration={1.62}
                    hex="#ffffff"
                    activeClassName={headerStyle.activeNavBarItem}
                    className={headerStyle.navBarLink}
                    to="/showcases"
                  >
                    Showcases
                  </AniLink>
                </li>
                <li className={headerStyle.navBarItem}>
                  <AniLink
                    paintDrip
                    duration={1.62}
                    hex="#ffffff"
                    activeClassName={headerStyle.activeNavBarItem}
                    className={headerStyle.navBarLink}
                    to="/videos"
                  >
                    Videos
                  </AniLink>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <ul className={classNames(headerStyle.navBar, 'd-none d-xl-flex')}>
          <li className={headerStyle.navBarItem}>
            <a
              className={classNames(headerStyle.actionButton, 'px-4 py-2 rounded-pill text-white')}
              href="tel:7148958865"
            >
              <i aria-hidden="true" className="phone icon" />
              CALL NOW
            </a>
          </li>
        </ul>
        <button
          className={classNames(
            'hamburger hamburger--emphatic d-sm-flex d-xs-flex d-md-flex d-lg-flex d-xl-none rounded',
            {
              'is-active': isActive
            }
          )}
          type="button"
          onClick={toggleMenu}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>
      <div
        className={headerStyle.mobileNavBarContainer}
        hidden={!isActive}
        id="mobile-nav-bar-container"
      >
        <ul className={headerStyle.mobileNavBar}>
          <li className={headerStyle.mobileNavBarItem}>
            <AniLink
              paintDrip
              duration={1.62}
              hex="#ffffff"
              activeClassName={headerStyle.activeNavBarItem}
              className={headerStyle.mobileNavBarLink}
              to="/"
            >
              HOME
            </AniLink>
          </li>
          <li className={headerStyle.mobileNavBarItem}>
            <AniLink
              paintDrip
              duration={1.62}
              hex="#ffffff"
              activeClassName={headerStyle.activeNavBarItem}
              className={headerStyle.mobileNavBarLink}
              to="/pedicure-spa-chairs/magna"
            >
              PEDICURE CHAIRS
            </AniLink>
          </li>
          <li className={headerStyle.mobileNavBarItem}>
            <AniLink
              paintDrip
              duration={1.62}
              hex="#ffffff"
              activeClassName={headerStyle.activeNavBarItem}
              className={headerStyle.mobileNavBarLink}
              to="/accessories"
            >
              ACCESSORIES
            </AniLink>
          </li>
          <li className={headerStyle.mobileNavBarItem}>
            <AniLink
              paintDrip
              duration={1.62}
              hex="#ffffff"
              activeClassName={headerStyle.activeNavBarItem}
              className={headerStyle.mobileNavBarLink}
              to="/about"
            >
              ABOUT
            </AniLink>
          </li>
          <li className={headerStyle.mobileNavBarItem}>
            <AniLink
              paintDrip
              duration={1.62}
              hex="#ffffff"
              activeClassName={headerStyle.activeNavBarItem}
              className={headerStyle.mobileNavBarLink}
              to="/contact-us"
            >
              CONTACT
            </AniLink>
          </li>
          <li className={headerStyle.mobileNavBarItem}>
            <a
              className={headerStyle.mobileNavBarLink}
              id="mobile-support-link"
              onClick={handleDropDownMenuClick}
            >
              SUPPORT
              <i
                aria-hidden="true"
                className="chevron down small icon"
                id="dropdown-icon-mobile-support-link"
                onClick={e => e.stopPropagation()}
              />
            </a>

            <div className={headerStyle.mobileDropdownMenu} id="for-mobile-support-link">
              <AniLink
                paintDrip
                duration={1.62}
                hex="#ffffff"
                activeClassName={headerStyle.activeNavBarItem}
                className={headerStyle.mobileDropdownLink}
                to="/faq/general"
              >
                FAQ
              </AniLink>
              <AniLink
                paintDrip
                duration={1.62}
                hex="#ffffff"
                activeClassName={headerStyle.activeNavBarItem}
                className={headerStyle.mobileDropdownLink}
                to="/user-manuals/novo"
              >
                User Manuals
              </AniLink>
              <AniLink
                paintDrip
                duration={1.62}
                hex="#ffffff"
                activeClassName={headerStyle.activeNavBarItem}
                className={headerStyle.mobileDropdownLink}
                to="/ip"
              >
                IP
              </AniLink>
              <AniLink
                paintDrip
                duration={1.62}
                hex="#ffffff"
                activeClassName={headerStyle.activeNavBarItem}
                className={headerStyle.mobileDropdownLink}
                to="/term-conditions"
              >
                Term & Conditions
              </AniLink>
            </div>
          </li>
          <li className={headerStyle.mobileNavBarItem}>
            <a
              className={headerStyle.mobileNavBarLink}
              id="mobile-media-link"
              onClick={handleDropDownMenuClick}
            >
              MEDIA
              <i
                aria-hidden="true"
                className="chevron down small icon"
                id="dropdown-icon-mobile-media-link"
                onClick={e => e.stopPropagation()}
              />
            </a>
            <div className={headerStyle.mobileDropdownMenu} id="for-mobile-media-link">
              <AniLink
                paintDrip
                duration={1.62}
                hex="#ffffff"
                activeClassName={headerStyle.activeNavBarItem}
                className={headerStyle.mobileDropdownLink}
                to="/showcases"
              >
                Showcases
              </AniLink>
              <AniLink
                paintDrip
                duration={1.62}
                hex="#ffffff"
                activeClassName={headerStyle.activeNavBarItem}
                className={headerStyle.mobileDropdownLink}
                to="/videos"
              >
                Videos
              </AniLink>
            </div>
          </li>

          <li className={headerStyle.mobileNavBarItem}>
            <a
              className={classNames(headerStyle.actionButton, 'px-4 py-2 rounded-pill text-white')}
              href="tel:7148958865"
            >
              <i aria-hidden="true" className="phone icon" />
              CALL NOW
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Header
