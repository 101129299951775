import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const finalMeta = [
    {
      name: `description`,
      content: metaDescription
    },
    {
      property: `og:title`,
      content: `${title} | ${site.siteMetadata.title}`
    },
    {
      property: `og:description`,
      content: metaDescription
    },
    {
      property: `og:type`,
      content: `website`
    },
    {
      name: `twitter:card`,
      content: `summary`
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author
    },
    {
      name: `twitter:title`,
      content: `${title} | ${site.siteMetadata.title}`
    },
    {
      name: `twitter:description`,
      content: metaDescription
    }
  ].concat(meta)

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      {finalMeta &&
        finalMeta.map(item => {
          if (item.name) {
            return <meta name={item.name} content={item.content} key={item.name} />
          }
          return <meta property={item.property} content={item.content} key={item.property} />
        })}
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css"
      />
      <link
        rel="stylesheet"
        href="//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css"
      />
      <script src="https://www.google.com/recaptcha/api.js" async defer />
    </Helmet>
  )
}
SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
}
SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}
export default SEO
